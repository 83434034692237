@import 'src/_metronic/_assets/sass/config';

.back-to-list-container {
  margin-top: 1.9rem;
  margin-bottom: 2.3rem;

  .back-to-list-button {
    display: flex;
    align-items: center;

    &:hover {
      .back-to-list-text {
        color: darken(kt-state-color(brand), 12%);
      }
    }

    .back-to-list-holder {
      background: #75b9e666;
      padding: 0.3rem;
      border-radius: 6px;
      margin-right: 1rem;
      align-self: center;
      width: 2.2rem;
      text-align: center;
      i {
        color: kt-state-color(brand);
      }
    }

    .back-to-list-text {
      transition: color 0.15s ease;
      color: kt-state-color(brand);
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
}
