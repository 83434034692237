//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}

@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}

/*ASIDE MENU - MENU VERTICAL*/

@mixin kt-menu-ver-minimize-skin($base-config, $layout) {
  .#{kt-get($base-config, class)} {
    // base vertical menu
    .kt-menu__nav {
      > .kt-menu__item {
        background: transparent;

        > .kt-menu__link {
          background-color: kt-get(
            $layout,
            minimize,
            item,
            link,
            self,
            bg-color,
            default
          );

          > .kt-menu__link-icon {
            color: kt-get(
              $layout,
              minimize,
              item,
              link,
              icon,
              font-color,
              default
            );
          }
        }

        &.kt-menu__item--hover,
        &.kt-menu__item--open {
          background: transparent !important;

          > .kt-menu__link {
            background-color: kt-get(
              $layout,
              minimize,
              item,
              link,
              self,
              bg-color,
              open
            );

            > .kt-menu__link-icon {
              @include kt-attr(
                color,
                kt-get($layout, minimize, item, link, icon, font-color, open)
              );
            }
          }
        }

        &.kt-menu__item--active,
        &:hover {
          background: transparent !important;

          > .kt-menu__link {
            > .kt-menu__link-icon {
              background-color: kt-state-color(brand);
              color: kt-state-color(brand, inverse);
              border-radius: 50%;
              // Due-tone icon
              svg {
                @include kt-svg-icon-color(kt-state-color(brand, inverse));
                path {
                  fill: kt-state-color(brand) !important;
                }
              }
            }
          }
        }

        @if kt-has($layout, minimize, item, submenu) {
          &.kt-menu__item--hover > .kt-menu__submenu {
            .kt-menu__subnav > .kt-menu__item.kt-menu__item--parent {
              display: flex;
              @include kt-menu-ver-minimize-parent-item-skin(
                kt-get($layout, minimize, item, submenu, parent-item)
              );
            }
          }
        }
      }

      > .kt-menu__section {
        .kt-menu__section-icon {
          color: kt-get($layout, minimize, section, icon, font-color);
        }
      }
    }
  }
}

/*TASKS*/

.kt-widget2 {
  .kt-widget2__item {
    animation: ease-in 0.5s;
  }
  .kt-widget2__item .kt-widget2__item--brand:before {
    background-color: kt-state-color(brand);
  }
  .kt-widget2__info {
    padding: 1.5rem 1.5rem 1.5rem 0;
    .kt-widget2__title {
      font-size: 11px !important;
      color: #bcc0cd !important;
    }
    .kt-widget2__username {
      font-weight: 500;
      font-size: 12px !important;
      color: #7a7b7c !important;
    }
  }
  .kt-widget2__actions {
    padding: 0 !important;
  }
}

/*border radius from btn*/
.btn {
  border-radius: 6px;
}
.btn-brand {
  &[disabled],
  &:focus,
  &.active,
  &:active,
  &:hover {
    color: #ffffff !important;
  }
}

.btn-grey {
  color: #A1A5B7!important;
  background-color: #E4E6EF !important;
}

/*titles with brand color*/
.title-brand {
  color: kt-state-color(brand);
}

// outline grey button
.btn-outline-secondary {
  background-color: transparent;
  color: #bcc0cd !important;
  color: #bcc0cd !important;
  &:focus,
  &.active,
  &:active,
  &:hover {
    color: kt-brand-color(inverse) !important;
    background-color: #bcc0cd !important;
    border-color: #bcc0cd !important;
  }
}

//grey background
.grey-background {
  padding: 1.5rem;
  background: #f7f8fa;
  border-radius: 9px;
  margin-bottom: 2rem;
}

//label
label {
  align-self: center;
  color: #7a7b7c;
  margin: 0;
  text-align: right;
}

//search block
.dropdown-menu-right {
  border-radius: 0 !important;
}

//QUICK ACTIONS
.kt-head__title {
  color: kt-state-color(brand);
  .kt-head__title--subtitle {
    color: #95989f !important;
  }
}
.kt-grid-nav__title {
  color: #bcc0cd;
}
.quick-actions-unicorn {
  .kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item {
    &:hover {
      background-color: white;
      .kt-grid-nav__title {
        color: kt-state-color(brand);
      }
      .quick-actions-icon {
        path {
          fill: kt-state-color(brand);
        }
      }
    }
  }
}

//dropzone
.document-uploaded {
  font-weight: 500;
  font-size: 13px;
  text-align: right;
  color: #9ac431;
}

// disabled inputs
.form-control,
input,
select {
  &.form-control-disabled,
  &:disabled,
  &[readonly] {
    background-color: #f9f9f9 !important;
    color: hsl(0, 0%, 60%);
    border-color: #abaaaa4d !important;
  }
}

// btn warning
.btn-warning {
  color: #ffffff;
  font-weight: 500;
  .show > &,
  &.active,
  &:active,
  &:hover,
  &:not(:disabled),
  &:not(.disabled):active {
    color: #ffffff !important;
    font-weight: 500 !important;
  }
}

.modal-title-draggable {
  cursor: move;
}
