@import 'src/_metronic/_assets/sass/config';

$text-color: #575962;

@mixin cell-padding() {
  &:first-child {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  &:last-child {
    padding: 0.5rem 0.5rem 0.5rem 0;
  }
  &:not(:first-child):not(:last-child) {
    padding: 0.5rem 0;
  }
}

@mixin hover-brand() {
  transition: color 0.15s ease, background-color 0.15s ease;

  &:not(:disabled):hover,
  &.active {
    background-color: kt-state-color(brand);
    color: kt-state-color(brand, inverse);
  }
}

.list-with-filters-container {
  .kt-portlet__head {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1rem;
    min-height: initial;

    .list-header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .list-header-content form {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      & > * {
        margin-right: 1.9rem;
        margin-top: 0.5rem;
      }

      .form-control {
        width: 14rem;
        border-radius: 1.6rem;
        border-style: solid;
        border-width: 0.7px;
      }

      .listbox-autocomplete {
        border-bottom-right-radius: 1.6rem;
        border-bottom-left-radius: 1.6rem;
      }

      .btn-search {
        width: 7.5rem;
      }

      .btn-expand {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        width: 29px;
        background-color: #ebedf2;
        border-color: #ebedf2;

        img {
          transition: transform 150ms ease-in-out;
        }
        &.extend {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.kt-list-container {
  width: 100%;

  .kt-list {
    width: 100%;
    box-sizing: border-box;
    // user-select: none;

    &.kt-list-primary {
      & > .kt-list-header {
        .kt-list-header-row {
          background-color: #f0f7ff;

          .kt-list-header-cell {
            color: $text-color;
            font-weight: 600;
            vertical-align: initial;
            @include cell-padding();

            .indicator-space {
              width: calc(9px + 0.3rem);
              height: 1px;
              display: inline-block;
            }
            svg {
              margin-left: 0.3rem;

              .path {
                fill: $text-color;
              }

              &.rotated {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      & > .kt-list-body {
        .kt-list-body-row {
          &:not(.selected) {
            &:not(:hover) {
              &:nth-child(odd) {
                background-color: #ffffff;
              }

              &:nth-child(even) {
                background-color: #f8fafb;
              }
            }

            &:hover {
              background-color: #e7eff6;
            }
          }

          &.selected {
            background-color: #d4e0ee;
          }

          .kt-list-body-cell {
            color: #7a7b7c;
            font-weight: 500;
            @include cell-padding();
          }
        }
      }
    }

    &.kt-list-secondary {
      & > .kt-list-header {
        .kt-list-header-row {
          background-color: kt-state-color(brand);

          .kt-list-header-cell {
            color: kt-state-color(brand, inverse);
            font-weight: 500;
            vertical-align: initial;
            @include cell-padding();

            .indicator-space {
              width: calc(9px + 0.3rem);
              height: 1px;
              display: inline-block;
            }

            svg {
              margin-left: 0.3rem;

              .path {
                fill: kt-state-color(brand, inverse);
              }

              &.rotated {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      & > .kt-list-body {
        .kt-list-body-row {
          border-bottom: 1px solid #f4f5f8;
          &:not(.selected) {
            background-color: #ffffff;
          }
          &.selected {
            background-color: #e7eff6;
          }

          .kt-list-body-cell {
            color: #7a7b7c;
            @include cell-padding();
          }
        }
      }
    }

    .actions-column {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      $icon-size: 1.5rem;
      & > *:not(:first-child) {
        margin-left: $icon-size;
      }

      .action-button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .save-button {
        color: kt-state-color(success);

        i {
          font-size: $icon-size;
        }
      }

      .edit-button {
        color: kt-state-color(info);

        i {
          font-size: $icon-size;
        }
      }

      .delete-button {
        color: kt-state-color(danger);

        i {
          font-size: $icon-size;
        }
      }

      .duplicate-button {
        color: #20a3d8 !important;
        text-decoration: none;

        i {
          font-size: $icon-size;
        }
      }
    }
  }

  .kt-list-pagination {
    margin-top: 3.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $text-color;
    font-weight: 300;
    font-size: 0.9rem;

    .kt-list-pagination-button {
      @include hover-brand();
      margin: 0 0.35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      height: 2rem;
      width: 2rem;
      border-radius: 1rem;
      background-color: #ebedf2;
      color: #868aa8;

      &:not(:hover):not(.active).kt-list-pagination-link {
        background-color: #ffffff;
        color: $text-color;
      }

      &:disabled {
        opacity: 0.4;
      }

      &:focus,
      &:focus {
        border: none;
        outline: none;
      }
    }

    .kt-list-pagination-selector {
      margin-left: 2rem;
      margin-right: 1rem;

      .dropdown-toggle {
        @include hover-brand();
        background-color: #f4f5f8;
        color: #868aa8;
        font-size: 0.9rem;
        width: 6.5rem;
        border-radius: 1.2rem;
        border: none;
        display: flex;
        justify-content: space-between;
        padding: 0.65rem 1.3rem;

        &:after {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.kt-list-body-row-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 50vw;
  height: 48px;
  background-color: #e7eff6;

  .kt-list-body-cell {
    color: #7a7b7c;
    font-weight: 500;
    @include cell-padding();
  }
}
