@import 'src/_metronic/_assets/sass/config';

.header {
  display: flex;
  justify-content: space-between;
}
.dashboard-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  color: kt-get($kt-state-colors, text, base);
  padding: 1rem;
}
.kt-portlet__head-title-company {
  font-weight: 700;
  font-size: 24px;
  color: kt-get($kt-state-colors, brand, base);
}
.company-project_combined {
  display: block!important;
}
.chart-holder {
  padding: 2rem;
}
.list-holder {
  margin: 0 auto;
  #licenses {
    thead {
      tr {
        background: #E5F5F9;
        height: 40px;
        th.kt-list-header-cell {
          font-weight: 600;
          font-size: 13px;
          color: kt-get($kt-state-colors, text, base);
          padding: 1rem!important;
        }
      }
    }
    tbody {
      tr {
        height: 44px;
        td.kt-list-body-cell {
          font-weight: 400;
          font-size: 12px;
        }
        td {
          padding: 0 1rem;
        }
      }
    }
  }
}
.kt-portlet__head-title {
  font-weight: 600;
  font-size: 15px;
  color: kt-get($kt-state-colors, text, base);
  &.license-title {
    margin-bottom: 3rem;
  }
}

.license-form {
  .checkboxes {
    justify-content: end;
  }
  .license-edit {
    display: flex;
    label {
      font-weight: 600;
      font-size: 13px;
    }
    .checkbox-button-label {
      font-size: 13px;
    }
    .date-input {
      width: 100%;
    }
  }
}
