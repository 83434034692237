@import '../../../../../_metronic/_assets/sass/config';
.checkbox-button-holder {
  display: flex;
  align-self: center;
  .checkbox-button {
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      cursor: pointer;
      border-radius: 6px;
      background: #F2F3F7;
      padding: 0.2rem 1rem;
    }
  }
  .checkbox-button-label {
    align-self: center;
    padding-left: 1rem;
  }
}
.checkbox-label {
  &.checked {
    .checkmark {
      cursor: pointer;
      border-radius: 6px;
      background: kt-get($kt-state-colors, brand, base);
      padding: 0.2rem 0.4rem!important;
      i {
        color: white;
      }
    }
  }
}
.hint-checkbox {
  float: right;
  margin-top: 1rem;
  padding-left: 20px;
}
