// Global config
@import "../../../config";
//
// Topbar
//

// Variables
$kt-header-topbar-item-size: 44px;
$kt-header-topbar-large-flag-size: 17px;
$kt-header-topbar-item-svg-size: 29px;
$kt-header-topbar-user-avatar-size: 29px;
$kt-header-topbar-item-size-mobile: 29px;
$kt-header-topbar-item-svg-size-mobile: 29px;
$kt-header-topbar-user-avatar-size-mobile: 29px;

.kt-header__topbar {
  display: flex;
  align-items: stretch;
  padding: 0;
  padding-right: 15px;
  .kt-menu-dropdowns {
    display: none;
  }

  .kt-header__topbar-item {
    display: flex;
    align-items: stretch;
    margin: 0;

    .kt-header__topbar-wrapper {
      cursor: pointer;
      display: flex;
      align-items: stretch;

      .kt-badge.kt-badge--notify {
        position: absolute;
        left: 50%;
        margin-left: -2px;
        top: 14px;
      }
    }

    .kt-header__topbar-icon {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      height: $kt-header-topbar-item-size;
      width: $kt-header-topbar-item-size;
      cursor: pointer;
      svg {
        background-color: kt-get($kt-state-colors, warning, base);
        padding: 0.5rem;
        border-radius: 9px;
      }
      @include kt-transition();

      @include kt-rounded {
        border-radius: $kt-border-radius;
      }

      i {
        @include kt-transition();
        font-size: 1.4rem;
        color: #ffffff;
      }

      /* svg {
				height: $kt-header-topbar-item-svg-size;
				width: $kt-header-topbar-item-svg-size;
			} */
    }

    // User profile
    &.kt-header__topbar-item--user {
      .kt-header__topbar-user {
        padding: 0 8px;
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        height: $kt-header-topbar-item-size;
        cursor: pointer;
        svg {
          background-color: kt-get($kt-state-colors, brand, base);
          padding: 0.5rem;
        }

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }

        .kt-header__topbar-welcome {
          display: flex;
          align-self: center;
          padding: 0 0.55rem 0 0;
          font-weight: 500;
          font-size: 0.9rem;
          color: #636177;
        }

        .kt-header__topbar-username {
          display: flex;
          align-self: center;
          padding: 0 0.55rem 0 0;
          font-weight: 500;
          font-size: 1rem;
          color: #fff;
        }

        img,
        svg {
          align-self: center;
          max-height: $kt-header-topbar-user-avatar-size;

          @include kt-rounded {
            border-radius: $kt-border-radius;
          }
        }

        .kt-badge.kt-badge--username {
          height: $kt-header-topbar-user-avatar-size;
          width: $kt-header-topbar-user-avatar-size;

          @include kt-rounded {
            border-radius: $kt-border-radius;
          }
        }
      }
    }

    // Languages
    &.kt-header__topbar-item--langs {
      padding: 1rem;
      &.lang-icon {
        .kt-header__topbar-icon {
          background-color: transparent!important;
        }
        .kt-header-language_name {
          align-self: center;
          font-weight: 400;
          font-size: 13px;
          color: kt-get($kt-state-colors, text, base);
          svg {
            margin-left: 0.5rem;
          }
        }
      }
      .kt-header__topbar-icon {
        img {
          @include kt-rounded {
            border-radius: $kt-border-radius;
          }

          width: $kt-header-topbar-large-flag-size;
        }
      }

      .dropdown-menu {
        .kt-nav__link-icon {
          padding-right: 10px;

          img {
            @include kt-rounded {
              border-radius: $kt-border-radius;
            }

            width: 18px;
          }
        }
      }
    }

    &:hover,
    &.show {
      .kt-header__topbar-icon {
        @include kt-transition();
        background-color: rgba(#212029, 0.7);

        i {
          @include kt-transition();
          color: #fff;
        }
      }

      &.kt-header__topbar-item--user {
        .kt-header__topbar-user {
          @include kt-transition();
        }
      }
    }
  }
}

@include kt-tablet-and-mobile {
  .kt-header__topbar {
    padding: 0 kt-get($kt-page-padding, mobile);
    background-color: #2b2a35;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
    height: kt-get($kt-header-config, topbar, height, mobile);
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    .kt-menu-dropdowns {
      display: flex;
      .kt-company-dropdown {
        padding: 1rem;
        button.btn-company {
          width: 180px;
          text-align: center;
          color: #ffffff;
          font-size: 13px;
          svg {
            margin-left: 0.8rem;
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    // Fixed mobile header
    .kt-header-mobile--fixed & {
      position: fixed;
      z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
    }

    // Topbar shown
    .kt-header__topbar--mobile-on & {
      margin-top: 0;
      transition: all 0.3s ease;
    }

    // Topbar item
    .kt-header__topbar-item {
      margin: 0 2px;
      padding: 0;

      .kt-header__topbar-wrapper {
        display: flex;
        align-items: stretch;

        .kt-badge.kt-badge--notify {
          top: 6px;
        }
      }

      .kt-header__topbar-icon {
        height: $kt-header-topbar-item-size-mobile;
        width: $kt-header-topbar-item-size-mobile;

        i {
          font-size: 1.3rem;
        }
      }

      &.kt-header__topbar-item--user {
        margin-left: 2px;
        margin-right: 0;
        padding: 0;

        .kt-header__topbar-user {
          padding: 0 2px;
          height: $kt-header-topbar-item-size-mobile;

          .kt-header__topbar-welcome {
            padding: 0 0.35rem 0 0;
            font-size: 0.9rem;
          }

          .kt-header__topbar-username {
            padding: 0 0.55rem 0 0;
            font-size: 0.9rem;
          }

          img,
          svg {
            max-height: $kt-header-topbar-user-avatar-size-mobile;
          }

          .kt-badge.kt-badge--username {
            height: $kt-header-topbar-user-avatar-size-mobile;
            width: $kt-header-topbar-user-avatar-size-mobile;
          }
        }
      }
    }
  }
}
