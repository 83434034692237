// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
// @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
// @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}
.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

/* Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}
*/

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.kt-page-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .btn {
      min-width: 10rem;
      font-size: 1.1rem;

      &:not(:last-child) {
        margin-right: 1.8rem;
      }
    }
  }
}

.kt-page-header {
  font-family: 'Poppins';
  color: #bcc0cd;
  font-size: 1.9rem;
  font-weight: 500;
}
.kt-section-header {
  font-family: 'Poppins';
  color: #bcc0cd;
  font-size: 1.4rem;
  font-weight: 500;
}
.kt-table-header {
  font-family: 'Roboto';
  color: #7a7b7c;
  font-size: 1.4rem;
  font-weight: 500;
}
.kt-default-text {
  font-size: 1rem;
  color: #7a7b7c;
  font-weight: 500;
}

// Default modal styling
.modal-body {
  font-family: 'Roboto';
  color: #7a7b7c;
  padding: 2.25rem;

  .modal-title {
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }
  .modal-message {
    font-weight: initial;
    font-size: 1.2rem;
  }
}
.modal-footer {
  border-top: none;
  padding: 1rem 2rem;

  .btn {
    min-width: 10rem;
    font-size: 1.2rem;
  }
}

.form-footer {
  border-top: none;
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .btn {
    min-width: 10rem;
    font-size: 1.1rem;

    &:not(:last-child) {
      margin-right: 1.8rem;
    }
  }
}

.portlet-side-menu {
  padding: 0;
  border-radius: $kt-border-radius;
  margin-right: 0;
  box-shadow: 3px 1px 6px rgba(69, 65, 78, 0.08);
}

.portlet-side-content {
  padding: 25px;
  margin-left: 0;
  border-top-right-radius: $kt-border-radius;
  border-bottom-right-radius: $kt-border-radius;
}

.kt-text-primary {
  color: kt-state-color(brand) !important;
}

.kt-checkbox {
  > input:checked ~ span {
    &:after {
      border: solid kt-state-color(brand);
    }
  }
}

#kt_wrapper {
  background-color: #F7F7FA!important;
}
