.otp-group {
    display: flex;
    width: 100%;
    max-width: 360px;
    grid-column-gap: 15px;
    column-gap: 15px;
    margin: auto;
}

.otp-input {
    width: 55px;
    height: 63px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
}
