// Global config
@import "../../../config";

//
// User Card
//

.kt-user-card {
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 1rem;

  // Elements
  .kt-user-card__avatar {
    .kt-badge,
    img {
      width: 60px;
      height: 60px;

      @include kt-rounded {
        border-radius: 4px;
      }
    }

    .kt-badge {
      font-size: 2.2rem;
    }
  }

  .kt-user-card__text {
    flex-grow: 1;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .kt-user-card__name {
      color: kt-get($kt-state-colors, text, base)!important;
      font-size: 1.3rem;
      font-weight: 700;
    }

    .kt-user-card__details {
      padding-top: 0.5rem;
      color: kt-base-color(label, 3);
      font-size: 1.1rem;
      font-weight: normal;
      &.role {
        color: kt-get($kt-state-colors, brand, base);
        padding: 0.5rem;
        background-color: kt-get($kt-state-colors, brand, inverse);
        border-radius: 4px;
      }
    }
  }

  .kt-user-card__badge {
    padding-left: 0.5rem;
  }

  // Skins
  &.kt-user-card--skin-light {
    .kt-user-card__name {
      color: kt-base-color(label, 3);
    }
    .kt-user-card__details {
      color: kt-base-color(label, 3);
    }

    .kt-user-card__avatar {
      .kt-badge {
        //background-color: rgba(#fff, 0.1);
      }
    }
  }

  &.kt-user-card--skin-dark {
    .kt-user-card__name {
      color: #fff;
    }
    .kt-user-card__details {
      color: #fff;
    }

    .kt-user-card__avatar {
      .kt-badge {
        background-color: rgba(#fff, 0.1);
      }
    }
  }
}
.kt-language-holder {
  padding: 1rem;
  .kt-language-title {
    font-weight: 600;
    font-size: 13px;
    color: kt-get($kt-state-colors, text, base);
  }
}
.kt-profile-holder {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  height: 69px;
  border-bottom: 1px solid #EBEDF2;
  &.admin-panel {
    background: #F7F8FA;
    &:hover {
      opacity: 0.4;
    }
  }
  &.user-profile {
    &:hover {
      opacity: 0.4;
    }
  }
  .kt-profile-title {
    font-weight: 600;
    font-size: 13px;
    color: kt-get($kt-state-colors, text, base);
    align-self: center;
    a {
      font-weight: 600;
      font-size: 13px;
      color: kt-get($kt-state-colors, text, base);
      align-self: center;
      padding: 0;
    }
    svg {
      margin-right: 1rem;
      width: 17px;
      height: 20px;
      path {
        fill: #B8BAC3;
      }
    }
  }
  svg {
    align-self: center;
  }
}

@include kt-tablet-and-mobile {
  .kt-user-card {
    padding: 1rem 1rem;

    // Elements
    .kt-user-card__avatar {
      .kt-badge,
      img {
        width: 40px;
        height: 40px;
      }
    }

    .kt-user-card__text {
      padding-left: 0.5rem;

      .kt-user-card__name {
        font-size: 1.1rem;
      }

      .kt-user-card__details {
        font-size: 0.9rem;
      }
    }
  }
}


.admin-panel-link, .user-profile-link {
  display: contents!important;
}
