.MuiFormControl-root.MuiTextField-root {
  display: flex;

  .MuiInput-underline {
    &::before, &::after {
      display: none;
    }
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;

    button {
      $input-height: calc(2.8rem + 2px);
      border-radius: 3.14px;
      height: $input-height;
      width: $input-height;
      margin-left: calc(#{$input-height} * -1);
    }
  }
}
